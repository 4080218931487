import SetupLogoNavbar from 'ui/SetupLayout/SetupLogoNavbar';
import { Outlet } from 'react-router-dom';

const TermsView = () => (
  <>
    <SetupLogoNavbar backgroundColor="white" />
    <Outlet />
  </>
);
export default TermsView;
