import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { LDProvider, ProviderConfig, useLDClient } from 'launchdarkly-react-client-sdk';
import { useBrandStore } from 'stores';
import { useParams } from 'react-router-dom';

/* this reloads the context when switching between brands */
const FeatureFlagContextSwitcher: React.FC<{}> = ({ children }) => {
  const { brand } = useBrandStore();
  const client = useLDClient();

  useEffect(() => {
    client?.identify({ key: brand.brandId, name: brand.name, kind: 'brand' });
  }, [brand]);

  return <>{children}</>;
};

const FeatureFlagProvider: React.FC<{}> = ({ children }) => {
  const { isLoading, user } = useAuth0();
  const { brand } = useBrandStore();
  const { brandId: paramBrandId } = useParams<{ brandId: string }>();

  const brandId = brand.brandId ?? paramBrandId;

  if (isLoading) return null;

  if (!user || !user.sub) return <>{children}</>;

  if (!brandId) return <>{children}</>;

  const providerState: ProviderConfig = {
    context: { key: brandId, name: brand.name, kind: 'brand' },
    clientSideID: import.meta.env.VITE_LD_CLIENT_SIDE_ID,
    timeout: 4999,
    options: {
      streaming: true,
    },
  };

  return (
    <LDProvider {...providerState}>
      <FeatureFlagContextSwitcher>{children}</FeatureFlagContextSwitcher>
    </LDProvider>
  );
};

export default FeatureFlagProvider;
