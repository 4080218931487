import { useEffect, useState } from 'react';
import { Shopify } from '@innovationdepartment/proxima-ui';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

import { useProximaSDK, useShopify, useShowSpinner } from 'hooks';
import { useBrandStore } from 'stores';
import { AUTH0_USER_ID_NAMESPACE } from 'constants/defaults';
import { Brand } from 'types/stores/brand-store';
import IntegrationsModal from 'ui/Modals/IntegrationsModal';
import ChooseBrandForShopifyView from './ChooseBrandForShopify.View';
import { IntegrationType } from 'constants/integrations';
import { IntegrationStatus } from 'types/stores/integration-store';
import { ShopifyAppRedirect } from 'types/components/shopify';

const ChooseBrandForShopify = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [search, setSeach] = useSearchParams();
  const { user, isLoading: loading } = useAuth0();
  const { setShopifyURLRedirect, getShopifyURLRedirect } = useShopify();
  const usersApi = useProximaSDK('UsersApi');
  const brandsApi = useProximaSDK('BrandsApi');
  const { brand: storeBrand, updateBrandStore } = useBrandStore();
  const integrationsApi = useProximaSDK('IntegrationsApi', { brandId: storeBrand?.brandId });

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [availableBrands, setAvailableBrands] = useState<Brand[]>([]);
  const [selectedBrandId, setSelectedBrandId] = useState<string | undefined>(undefined);

  const userId = user?.[AUTH0_USER_ID_NAMESPACE] ?? '';

  useShowSpinner({ show: isLoading });

  const fetchBrandsForUser = async () => {
    if (!userId) return;

    setIsLoading(true);
    setIsError(false);
    setSelectedBrandId(undefined);

    try {
      const response = await usersApi.getAllBrandsForUser({ userId });

      const brandIds = response.data.map((brand) => brand.brandId);
      const brands = await brandsApi.getBrandBatch({ brandId: brandIds.join(',') });

      setAvailableBrands(brands.data.sort((b1, b2) => b1.name.localeCompare(b2.name)) as Brand[]);
    } catch (err) {
      /** */
    }

    setIsLoading(false);
  };

  const onSelectBrand = async (brandId: string) => {
    const selectedBrand = availableBrands.find((brand) => brand.brandId === brandId);
    setSelectedBrandId(
      selectedBrand?.brandId === selectedBrandId ? undefined : selectedBrand?.brandId
    );

    if (selectedBrand) {
      updateBrandStore(selectedBrand);
      /* set brandId to avoid FeatureFlagProvider to re-reload page cuz store is not updated */
      const searchParams = new URLSearchParams(search);
      searchParams.set('brandId', selectedBrand.brandId);
      setSeach(searchParams);
    }
  };

  const onIntegrateShopifyForBrand = async () => {
    setIsLoading(true);
    if (selectedBrandId) {
      const selectedBrand = availableBrands.find((brand) => brand.brandId === selectedBrandId);
      if (!selectedBrand) return;

      try {
        const response = await integrationsApi.getIntegrationsForBrand({
          brandId: selectedBrandId,
        });
        if (response.data.length) {
          const brandAlreadyIntegrated = response.data.some(
            (integration) =>
              integration.type === IntegrationType.Shopify &&
              integration.status === IntegrationStatus.Active
          );
          if (brandAlreadyIntegrated) {
            setIsError(true);
            setIsLoading(false);
            return;
          }
        }
      } catch (err) {
        setIsError(true);
        setIsLoading(false);
        return;
      }
    }

    setIsLoading(false);

    setTimeout(() => {
      navigate(`/auth/callback/shopify/integrate${location.search}`);
    }, 10);
  };

  const onCreateNewBrandClick = () => {
    setShopifyURLRedirect({ key: ShopifyAppRedirect.Signup, expireInMs: 1000 * 60 * 60 * 3 });
    navigate('/setup/brand');
  };

  const onClose = () => {
    const onboardingUrl = getShopifyURLRedirect({ remove: true });

    navigate(onboardingUrl ?? '/brands');
  };

  useEffect(() => {
    fetchBrandsForUser();
  }, [loading]);

  return (
    <IntegrationsModal open full title="Shopify" icon={<Shopify />} onClose={onClose}>
      <ChooseBrandForShopifyView
        brands={availableBrands}
        loading={isLoading}
        error={isError}
        onBrandSelect={onSelectBrand}
        selectedBrandId={selectedBrandId}
        onRefreshBrandsClick={fetchBrandsForUser}
        onContinueClick={onIntegrateShopifyForBrand}
        onCreateNewBrandClick={onCreateNewBrandClick}
      />
    </IntegrationsModal>
  );
};

export default ChooseBrandForShopify;
