import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import SetupLogoNavbar from './SetupLogoNavbar';

const show = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const CardWrapper = styled.div<{ w?: string }>`
  margin-top: 32px;
  width: ${(props) => props.w ?? '422px'};
  opacity: 0;
  animation: ${show} 0.25s ease-in-out forwards;
`;

const MainWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

// this applies a nice fade-in effect to the elements
const SetupLayout: React.FC = ({ children }) => {
  const location = useLocation();

  // custom width for specific setups
  const mappings: { [key: string]: string } = { '/ads': '640px', '/facebook': 'unset' };

  const setupLocation = location.pathname.slice('/setup'.length);
  const width: string = mappings[setupLocation] ?? '422px';

  return (
    <MainWrapper>
      <SetupLogoNavbar />
      <CardWrapper w={width}>{children}</CardWrapper>
    </MainWrapper>
  );
};

export default SetupLayout;
