import * as date from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import { CustomerAdsViewProps } from './types';
import { useHandleApi, useProximaSDK } from 'hooks';
import { PAGE_DEFAULT_SIZE } from 'types/components/fbTable';
import { CreativeAd, CreativeType, VideoMeta } from 'types/components/creatives';
import { useBrandStore } from 'stores';
import useCreativeAdsManager from '../useCreativeAdsManager';
import prepareQueryString from 'utils/prepareQueryString';
import { formatDateToIsoWithoutTime } from 'utils/formatDate';
import { DatePickerProps } from '@innovationdepartment/proxima-ui';
import { BrandItem } from '@innovationdepartment/proxima-sdk-axios';
import useCreativesBoard from 'hooks/useCreativesBoard';

const endDate = date.endOfYesterday();
const startDate = date.sub(new Date(), { days: 30 });

type QueryStringParameters = {
  startDate?: string;
  endDate?: string;
  pageNumber?: number;
  pageSize?: string;
  brandId: string;
};

type DateRange = Required<DatePickerProps['dateRange']>;

const useColalAdsManager = () => {
  const tilesContainerRef = useRef<HTMLDivElement>(null);

  const { brand: currentBrand } = useBrandStore();
  const { loading: creativeAdsLoading, getVideoMetaFromAssets } = useCreativeAdsManager();
  const {
    loading: creativeBoardLoading,
    saveAdToBoard,
    deleteAdFromBoard,
    savedAds,
    saving,
  } = useCreativesBoard();
  const { loading: handlerLoading, handleApi } = useHandleApi();
  const brandsApi = useProximaSDK('BrandsApi');

  const [page, setPage] = useState(1);
  const [dateRange, setDateRange] = useState<DateRange>({ endDate, startDate });
  const [ads, setAds] = useState<CustomerAdsViewProps['ads']>([]);
  const [loading, setLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);
  const [videoMeta, setVideoMeta] = useState<Map<number, VideoMeta>>(new Map());
  const [brands, setBrands] = useState<Map<string, BrandItem>>(new Map());

  const getColalAds = async (queryItems: QueryStringParameters) => {
    const queryString = prepareQueryString({
      pageSize: PAGE_DEFAULT_SIZE,
      pageNumber: 1,
      ...queryItems,
    });

    const response = await handleApi({ endpoint: `/fb/ads/colal?${queryString}` });

    return response;
  };

  const onFetchNext = async () => {
    const resetState = () => {
      setPage(() => 1);
      setHasNextPage(true);
      setAds([]);
      setVideoMeta(new Map());
    };

    const fetchBrandsFromAds = async (nextBatchIds: string[]) => {
      const brandIds = new Set<string>(nextBatchIds);
      /* filter brands that we already have from other batch calls */
      nextBatchIds.forEach((brandId) => {
        if (brands?.has(brandId)) brandIds.delete(brandId);
      });

      if ([...nextBatchIds].length === 0) return;

      /* fetch new brands while more ads are loaded */
      const getBrandsFromApi = async () => {
        const response = await brandsApi.getBrandBatch({ brandId: [...brandIds].join(',') });

        if (response.status === 200) {
          setBrands((prevBrands) => {
            const newBrands = new Map<string, BrandItem>(prevBrands);

            response.data.forEach((brand) => newBrands.set(brand.brandId as string, brand));

            return newBrands;
          });
        }
      };

      getBrandsFromApi();
    };

    const fetchVideoMetaFromAssets = async (nextBatchAds: CreativeAd[]) => {
      const assetIds = new Set<number>();
      /* iterate through every ad */
      nextBatchAds.forEach((ad) => {
        const { creativeAssets = [] } = ad;
        if (!creativeAssets.length) return;

        /* iterate through every video asset from each ad */
        creativeAssets.forEach((next) => {
          if (!creativeAssets.length) return;
          if (next.permalinkType !== CreativeType.VIDEO) return;
          if (videoMeta.has(next.id)) return;
          assetIds.add(next.id);
        });
      });

      if ([...assetIds].length === 0) return;

      /* fetch new video meta while more ads are loaded */
      const meta = await getVideoMetaFromAssets([...assetIds]);

      /* save new meta in store */
      setVideoMeta((prevMeta) => {
        const newMeta = new Map<number, VideoMeta>(prevMeta);
        Object.entries(meta).forEach(([key, value]) => newMeta.set(parseInt(key, 10), value));
        return newMeta;
      });
    };

    setLoading(true);
    try {
      const queryItems = {
        endDate: formatDateToIsoWithoutTime(dateRange.endDate),
        startDate: formatDateToIsoWithoutTime(dateRange.startDate),
        brandId: currentBrand.brandId,
      };

      /* get ads data */
      const response = await getColalAds({ ...queryItems, pageNumber: page });
      const newAds = response.data.ads as unknown as CreativeAd[];

      /* retrieve (new) brands info */
      const brandIds = [...new Set(newAds.map((ad) => ad.brandId))];

      /* fetch data */
      await Promise.all([fetchBrandsFromAds(brandIds), fetchVideoMetaFromAssets(newAds)]);

      /* set ads *AFTER* video data was loaded */
      setAds((prevAds) => (page === 1 ? newAds : [...prevAds, ...newAds]));

      if (page === 1) tilesContainerRef.current?.scrollTo({ top: 0 });

      setPage((prevPage) => prevPage + 1);
      setHasNextPage(response.data.pageSize >= PAGE_DEFAULT_SIZE);
    } catch (e) {
      /* reset ads and page on error */
      resetState();
    } finally {
      setLoading(false);
    }
  };

  const onSaveAdButtonClick = async (adId: string) => {
    const isSaved = savedAds.includes(adId);

    if (isSaved) {
      await deleteAdFromBoard(adId);
    } else {
      await saveAdToBoard(adId);
    }
  };

  const onDateRangeChange = (dates: DateRange) => {
    setPage(() => 1);

    setDateRange(dates);
  };

  useEffect(() => {
    onFetchNext();
  }, [dateRange]);

  const formatAds = () => {
    const formatCreativeAssets = (asset: NonNullable<CreativeAd['creativeAssets']>[number]) => {
      const { assetId } = asset;
      if (!assetId) return asset;
      const meta = videoMeta.get(+assetId);
      return {
        ...asset,
        videoMeta: meta,
      };
    };

    return ads.map((ad) => ({
      ...ad,
      brand: brands.get(ad.brandId as string),
      /* this should be handled in the backend */
      creativeType: ad.creativeAssets?.some((asset) => asset.permalinkType === CreativeType.VIDEO)
        ? 'VIDEO'
        : 'STATIC',
      /* map video meta to assets */
      creativeAssets: ad.creativeAssets?.map(formatCreativeAssets),
      isSaved: savedAds.includes(ad.adId),
    })) as unknown as CreativeAd[];
  };

  const formattedAds = formatAds();

  const isLoading =
    loading || creativeAdsLoading || handlerLoading || (!saving && creativeBoardLoading);

  return {
    ads: formattedAds,
    loading: isLoading,
    dateRange,
    tilesContainerRef,
    onFetchNext: hasNextPage ? onFetchNext : undefined,
    onDateRangeChange,
    onSaveAdButtonClick,
  };
};

export default useColalAdsManager;
