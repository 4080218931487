import * as date from 'date-fns';
import { useBrandStore } from 'stores';
import CustomerAdsView from './CustomerAds.View';
import { useEffect, useState } from 'react';
import { CreativeAd } from 'types/components/creatives';
import useColalAdsManager from './useColalAdsManager';
import CustomerAdsCreativeQuickviewModal from './Layout/CustomerAdsCreativeQuickviewModal';
import ShopifyIntegrationModal from 'ui/Modals/ShopifyIntegrationModal';
import { useShopify, useShowSpinner } from 'hooks';
import { BrandIntegrationStatus } from 'constants/integrations';

const endDate = date.endOfYesterday();
const startDate = date.sub(new Date(), { days: 30 });

const CustomerAds = () => {
  const { brand } = useBrandStore();
  const { getShopifyStatus } = useShopify();
  const { ads, loading, tilesContainerRef, onDateRangeChange, onFetchNext, onSaveAdButtonClick } =
    useColalAdsManager();

  const [shopifyStatus, setShopifyStatus] = useState<BrandIntegrationStatus>();
  const [showPreview, setShowPreview] = useState(false);
  const [adToPreview, setAdToPreview] = useState<CreativeAd | null>(null);
  const [dateRange, setDateRange] = useState({
    endDate,
    startDate,
  });
  const [showShopifyIntegrationModal, setShowShopifyIntegrationModal] = useState(false);

  const onTileClick = (creativeAd: CreativeAd) => {
    setAdToPreview(creativeAd);
    setShowPreview(true);
  };

  const onDateRangeSelect = (newDateRange: any) => {
    setDateRange(newDateRange);
    onDateRangeChange(newDateRange);
  };

  const onCloseModal = () => {
    setShowPreview(false);
    setTimeout(() => {
      setAdToPreview(null);
    }, 250);
  };

  useEffect(() => {
    const initialShopifyStatus = getShopifyStatus();

    setShopifyStatus(initialShopifyStatus);
  }, []);

  useShowSpinner({ show: loading });

  return (
    <>
      <CustomerAdsView
        tilesContainerRef={tilesContainerRef}
        ads={ads}
        dateRange={dateRange}
        brand={brand}
        loading={loading}
        shopifyStatus={shopifyStatus!}
        onConnectShopifyAccountClicked={() => setShowShopifyIntegrationModal(true)}
        onFetchNext={onFetchNext}
        onTileClick={onTileClick}
        onDateRangeSelect={onDateRangeSelect}
        onToggleSaveAd={onSaveAdButtonClick}
      />
      <CustomerAdsCreativeQuickviewModal
        open={showPreview}
        ad={adToPreview}
        onClose={onCloseModal}
        onToggleSaveAd={onSaveAdButtonClick}
      />
      <ShopifyIntegrationModal
        onClose={() => setShowShopifyIntegrationModal(false)}
        open={showShopifyIntegrationModal}
      />
    </>
  );
};

export default CustomerAds;
