import styled from '@emotion/styled';
import CustomerAdsFilters from './Layout/CustomerAdsFilters';
import { CustomerAdsViewProps } from './types';
import CustomerAdsTiles from './Layout/CustomerAdsTiles';
import { BrandIntegrationStatus } from 'constants/integrations';
import { CustomerAdsNoColal, CustomerAdsNoShopifyIntegration } from './Layout/CustomerAdsCover';
import { InsightsHeader } from '../SharedLayout';

/* this needs to go into a shared layout component */
const CustomerAdsInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px 32px 0;
  background-color: white;
  height: calc(100vh - 69px);
`;

const CustomerAdsStickyTop = styled.div`
  position: sticky;
  top: 0;
  z-index: 3;
`;

const CustomerAdsView = (props: CustomerAdsViewProps) => {
  const { ads, brand, shopifyStatus, onConnectShopifyAccountClicked } = props;

  const showIntegrationScreen = shopifyStatus && shopifyStatus !== BrandIntegrationStatus.Connected;
  const showNoAdsScreen =
    shopifyStatus && ads.length === 0 && shopifyStatus === BrandIntegrationStatus.Connected;

  return (
    <>
      {showNoAdsScreen && <CustomerAdsNoColal />}
      {showIntegrationScreen && (
        <CustomerAdsNoShopifyIntegration
          onConnectShopifyAccountClicked={onConnectShopifyAccountClicked}
        />
      )}
      <CustomerAdsStickyTop>
        <InsightsHeader brandName={brand.name} title="Ads Your Customers Love" helpLink="" />
      </CustomerAdsStickyTop>
      <CustomerAdsInnerContainer>
        <CustomerAdsFilters {...props} />
        <CustomerAdsTiles {...props} />
      </CustomerAdsInnerContainer>
    </>
  );
};

export default CustomerAdsView;
