import { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useLocation } from 'react-router-dom';

type GaTagProps = {
  children: React.ReactNode;
};

const GaTag = (props: GaTagProps) => {
  const { children } = props;
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID);
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    });
  }, [location]);

  return <>{children}</>;
};

export default GaTag;
