import styled from '@emotion/styled';
import { Button, colors, PrivacyOnSmall, Text } from '@innovationdepartment/proxima-ui';
import { CATEGORIES } from 'constants/categories';
import AdTile from 'components/Insights/SharedLayout/AdCreativeTile';
import { truncateText } from 'components/Insights/MyCreative/Layout/helpers';
import AdTileSaveButton from 'components/Insights/SharedLayout/AdCreativeTile/AdCreativeTile.SaveButton';
import { AdCreativesTilesTileProps } from './types';

const CustomButton = styled(Button)<{ small?: boolean; top?: number }>`
  padding: 4px 12px;
  min-width: unset;
  border-color: ${colors.neutral10};

  ${({ small }) => {
    if (!small) return '';

    return `
      max-width: 48px;
    `;
  }}

  ${({ top = 24 }) => `margin-top: ${top}px;`}
`;

const AdCreativesTilesTile = (props: AdCreativesTilesTileProps) => {
  const { ad, thumbnail, tag, onTileClick, onSaveButtonClick } = props;

  /* TODO(Jenky): to add when the functionality is brought up from Product */
  const onButtonClick = () => {};

  return (
    <AdTile
      ad={ad}
      thumbnail={thumbnail}
      onTileClick={onTileClick}
      tag={tag}
      cardTitle={truncateText(ad.brand?.name ?? '')}
      bodyElements={
        <div>
          <CustomButton
            small
            label={undefined}
            onClick={onButtonClick}
            color="secondary"
            variant="outlined"
          >
            <PrivacyOnSmall />
          </CustomButton>
          {onSaveButtonClick && (
            <AdTileSaveButton
              saved={ad.isSaved ?? false}
              onClick={() => {
                onSaveButtonClick(ad.adId);
              }}
            />
          )}
        </div>
      }
      titleElements={
        ad?.category && (
          <Text color="neutral70" variant="body2Medium">
            {CATEGORIES[ad.category]}
          </Text>
        )
      }
    />
  );
};

export default AdCreativesTilesTile;
