import styled from '@emotion/styled';
import {
  colors,
  InfoDarkSmall,
  MetaNoBorder,
  Text,
  Tooltip,
} from '@innovationdepartment/proxima-ui';

const AlignCenter = styled.div<{ gap?: number }>`
  display: flex;
  align-items: center;
  gap: ${({ gap = 8 }) => gap}px;
`;

const TooltipWrapper = styled(AlignCenter)`
  padding-top: 2px;

  & :is(svg, path) {
    fill: ${colors.neutral60};
  }
`;

const IconWrapper = styled.div`
  & svg {
    display: flex;
    align-items: center;
    height: 24px;
    width: 24px;
  }
`;

// TODO(MM): waiting on customer ads specific copy from Colin
const TOOLTIP_CONTENT = (
  <div style={{ maxWidth: 260 }}>
    <Text variant="body2Semibold" color="white">
      Top performers are in the top 20% of performance for a given metric, on a per brand basis.
      <br />
      <br />
      Overall top performers are in the top 20% of ROAS, CTR, and Spend.
    </Text>
  </div>
);

const CustomerAdsFiltersStaticCopy = () => (
  <>
    <AlignCenter>
      <IconWrapper>
        <MetaNoBorder />
      </IconWrapper>
      <Text variant="body1Semibold" color="neutral90">
        Meta Top Performers
      </Text>
    </AlignCenter>
    <Tooltip placement="top-start" arrow size="small" title={TOOLTIP_CONTENT}>
      <TooltipWrapper gap={4}>
        <InfoDarkSmall />
        <Text variant="body3Semibold" color="neutral60">
          What does this mean?
        </Text>
      </TooltipWrapper>
    </Tooltip>
  </>
);

export default CustomerAdsFiltersStaticCopy;
