import { AdsBoard, AdsBoardOwnerTypeEnum } from '@innovationdepartment/proxima-sdk-axios';
import { useToaster } from '@innovationdepartment/proxima-ui';
import { useFeatureFlag, useProximaSDK } from 'hooks';
import { useEffect, useState } from 'react';
import { useBrandStore } from 'stores';

const useCreativesBoard = () => {
  const { brand } = useBrandStore();
  const creativesApi = useProximaSDK('CreativesApi');
  const [board, setBoard] = useState<AdsBoard | null>(null);
  const [savedAds, setSavedAds] = useState<string[]>([]);
  const [saving, setSaving] = useState<boolean>(false);
  const [loaded, setLoaded] = useState(false);
  const { savedAds: savedAdsFlag } = useFeatureFlag();
  const { showToaster } = useToaster();

  const createCreativeAdsBoard = async () => {
    const boardResponse = await creativesApi.createBoard({
      createAdBoardInput: { ownerId: brand.brandId, ownerType: AdsBoardOwnerTypeEnum.BRAND },
    });

    return boardResponse.data;
  };

  const getCreativeAdsBoard = async () => {
    /* for the time being, we're just using one board until we include the functionality to create boards at will */
    const boardsResponse = await creativesApi.getBoards({
      ownerId: brand.brandId,
      type: AdsBoardOwnerTypeEnum.BRAND,
    });

    if (!boardsResponse.data.length) return null;

    const boardResponse = await creativesApi.getBoard({
      ownerId: brand.brandId,
      boardId: boardsResponse.data[0].id,
      type: AdsBoardOwnerTypeEnum.BRAND,
    });

    return boardResponse.data ?? null;
  };

  const getCreativeAdsBoardAds = async () => {
    let currentBoard = board;
    if (!currentBoard) {
      currentBoard = await getCreativeAdsBoard();
    }

    if (!currentBoard) return [];

    const adsResponse = await creativesApi.getBoardAds({
      ownerId: brand.brandId,
      boardId: `${currentBoard?.id}`,
      type: AdsBoardOwnerTypeEnum.BRAND,
    });

    return adsResponse.data.adIds ?? [];
  };

  const saveAdToBoard = async (adId: string) => {
    setSaving(true);
    let currentBoard = board;
    if (!currentBoard) {
      currentBoard = await createCreativeAdsBoard();
      setBoard(currentBoard);
    }

    await creativesApi.saveAdsToBoard({
      ownerId: brand.brandId,
      boardId: `${currentBoard.id}`,
      type: AdsBoardOwnerTypeEnum.BRAND,
      saveAdsToBoardInput: { adId: [adId] },
    });

    showToaster({ message: 'Ad added to saved ads', variant: 'success' });
    setSavedAds([...savedAds, adId]);
    setSaving(false);
  };

  const deleteAdFromBoard = async (adId: string) => {
    if (!board) return;

    setSaving(true);
    await creativesApi.deleteAdsFromBoard({
      boardId: `${board.id}`,
      type: AdsBoardOwnerTypeEnum.BRAND,
      adId: [adId].join(','),
      ownerId: brand.brandId,
    });

    showToaster({ message: 'Ad removed from saved ads', variant: 'warning' });

    const updatedSavedAds = [...savedAds].filter((savedAd) => savedAd !== adId);

    setSavedAds(updatedSavedAds);
    setSaving(false);
  };

  /* might move this out of here, leave it to components where these are being loaded, tbd */
  useEffect(() => {
    const fetchBoard = async () => {
      setLoaded(false);
      setBoard(await getCreativeAdsBoard());
      setSavedAds(await getCreativeAdsBoardAds());
      setLoaded(true);
    };

    if (savedAdsFlag) fetchBoard();
  }, [savedAdsFlag]);

  return {
    board,
    loaded,
    savedAds,
    loading: creativesApi.loading,
    saving,
    createCreativeAdsBoard,
    getCreativeAdsBoard,
    getCreativeAdsBoardAds,
    saveAdToBoard,
    deleteAdFromBoard,
  };
};

export default useCreativesBoard;
