import { formatDateFromIso } from 'utils';
import { MyCreativeQuickviewModalProps } from './types';
import styled from '@emotion/styled';
import { LabelStatus, Text } from '@innovationdepartment/proxima-ui';
import { dollarDisplayValue } from '../helpers';

const DetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;

  & > :first-child {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const SubdetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const MyCreativeQuickviewSubdetails = (
  props: Omit<MyCreativeQuickviewModalProps, 'onClose' | 'open'>
) => {
  const { ad } = props;

  const status = ad?.status === 'ACTIVE' ? 'Active' : 'Inactive';
  const statusType = ad?.status === 'ACTIVE' ? 'success' : 'error';

  const launchDate = ad?.launchDate ? formatDateFromIso(ad?.launchDate) : '';

  return (
    <SubdetailsWrapper>
      <DetailsWrapper>
        <Text variant="body2Semibold" color="neutral70">
          Status
        </Text>
        <LabelStatus label={status} variant={statusType} />
      </DetailsWrapper>
      <DetailsWrapper>
        <Text variant="body2Semibold" color="neutral70">
          Launch Date
        </Text>
        <Text variant="body2Link">{launchDate}</Text>
      </DetailsWrapper>
      <DetailsWrapper>
        <Text variant="body2Semibold" color="neutral70">
          Spend
        </Text>
        <Text variant="body2Link">{dollarDisplayValue(ad?.spend)}</Text>
      </DetailsWrapper>
      <DetailsWrapper>
        <Text variant="body2Semibold" color="neutral70">
          Landing Page
        </Text>
        <Text variant="body2Link" color="blue70">
          {ad?.linkUrl ?? '--'}
        </Text>
      </DetailsWrapper>
    </SubdetailsWrapper>
  );
};

export default MyCreativeQuickviewSubdetails;
