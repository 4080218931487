import styled from '@emotion/styled';
import { colors } from '@innovationdepartment/proxima-ui';
import { CustomerAdsCreativeQuickviewModalProps } from './types';
import LoadingSpinner from 'ui/LoadingSpinner';
import VideoPlayer from 'ui/VideoPlayer';
import { VideoMeta } from 'types/components/creatives';
import AdCreativeTileSaveButton from 'components/Insights/SharedLayout/AdCreativeTile/AdCreativeTile.SaveButton';

const PreviewContentWrapper = styled.div<{ clickable: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 454px;
  height: 525px; /* huh? */
  background-color: ${colors.neutral0};
  padding: 32px;

  ${({ clickable }) => (clickable ? 'cursor: pointer;' : '')}
`;

const ThumbnailWrapper = styled.div<{ src?: string }>`
  border-radius: 4px;
  height: 420px;
  width: 360px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url(${(props) => props.src});
`;

const CustomerAdsCreativeQuickviewPreviewView = (
  props: CustomerAdsCreativeQuickviewModalProps & {
    previewUrl: null | string;
    loading: boolean;
    isVideo: boolean;
    meta: VideoMeta | null;
    // temporary fix for the video player
    onClickForVideo?: () => void;
    onToggleSaveAd: (adId: string) => void;
  }
) => {
  const { ad, loading, isVideo, previewUrl, meta, onClickForVideo, onToggleSaveAd } = props;

  const isPublicVideo = !!onClickForVideo;
  const isPhoto = !isVideo && !isPublicVideo;
  const showPreview = Boolean(!loading && previewUrl);

  const onSaveAdButtonClick = () => {
    if (ad) onToggleSaveAd(ad.adId);
  };

  const isSaved = ad?.isSaved ?? false;

  return (
    <PreviewContentWrapper onClick={onClickForVideo} clickable={isPublicVideo}>
      {loading && <LoadingSpinner />}
      {showPreview && (
        <>
          {isPhoto && <ThumbnailWrapper src={previewUrl as string} />}
          {isVideo && !isPublicVideo && <VideoPlayer meta={meta} />}
          {isPublicVideo && <div>Click to view on Facebook</div>}
          <AdCreativeTileSaveButton saved={isSaved} onClick={onSaveAdButtonClick} />
        </>
      )}
    </PreviewContentWrapper>
  );
};

export default CustomerAdsCreativeQuickviewPreviewView;
