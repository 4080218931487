import styled from '@emotion/styled';
import { colors } from '@innovationdepartment/proxima-ui';
import { CreativeType } from 'types/components/creatives';
import MyCreativeTilesTile from './MyCreative.Tiles.Tile';
import { MyCreativeTilesProps } from '../../types';
import { creativeTypeTag, getCreativeAsset } from 'components/Insights/helpers';
import InfiniteScroll from 'ui/InfiniteScroll';

const TilesContainer = styled.div`
  position: relative;
  margin-top: 24px;
  background-color: ${colors.white};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 16px;
  overflow: auto;
  padding: 0 32px 32px;

  & > * {
    justify-self: center;
  }
`;

// TODO: maybe refactor, use AdCreativesTiles component instead?
const MyCreativeTiles = (props: MyCreativeTilesProps) => {
  const { ads, loading, tilesContainerRef, onTileClick, onFetchNext } = props;

  return (
    <TilesContainer ref={tilesContainerRef}>
      {ads.map((ad, index) => {
        const { type, thumbnail } = getCreativeAsset(ad);

        const showInfiniteScroll = index === ads.length - 1 && !loading;
        const tag =
          (type !== undefined ? creativeTypeTag[type] : undefined) ??
          creativeTypeTag[CreativeType.PHOTO];

        return (
          <>
            <MyCreativeTilesTile
              key={ad.adId}
              ad={ad}
              tag={tag}
              thumbnail={thumbnail}
              onTileClick={onTileClick}
            />
            {showInfiniteScroll && <InfiniteScroll onInfiniteScroll={onFetchNext} />}
          </>
        );
      })}
    </TilesContainer>
  );
};

export default MyCreativeTiles;
