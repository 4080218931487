import { DatePicker } from '@innovationdepartment/proxima-ui';
import { CustomerAdsFiltersProps } from '../../types';

const CustomerAdsFiltersDatePicker = (props: CustomerAdsFiltersProps) => {
  const { dateRange, onDateRangeSelect } = props;
  return (
    <DatePicker
      disabled // TODO(Jenky): temporary until we have filters working properly
      onDateRangeSelect={(dates) => onDateRangeSelect(dates)}
      showSelectWithDateRange={false}
      dateRange={dateRange}
    />
  );
};

export default CustomerAdsFiltersDatePicker;
