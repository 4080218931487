import styled from '@emotion/styled';

import { AdCreativesTilesProps } from './types';
import AdCreativesTilesTile from './AdCreativesTiles.Tile';
import { colors, CustomCard } from '@innovationdepartment/proxima-ui';
import { CreativeType } from 'types/components/creatives';
import { PAGE_DEFAULT_SIZE } from 'types/components/fbTable';

import { creativeTypeTag, getCreativeAsset } from 'components/Insights/helpers';
import InfiniteScroll from 'ui/InfiniteScroll';

const TilesContainer = styled.div`
  position: relative;
  margin-top: 24px;
  background-color: ${colors.white};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 16px;
  overflow: auto;
  padding: 0 16px 16px;

  & > * {
    justify-self: center;
  }
`;

const LOADING_TILE_COUNT = PAGE_DEFAULT_SIZE;

const AdCreativesTiles = (props: AdCreativesTilesProps) => {
  const { ads, loading, tilesContainerRef, onToggleSaveAd, onTileClick, onFetchNext } = props;

  return (
    <TilesContainer ref={tilesContainerRef}>
      {ads.map((ad, index) => {
        const { type, thumbnail } = getCreativeAsset(ad);

        const showInfiniteScroll = index === ads.length - 1 && !loading;

        const tag =
          (type !== undefined ? creativeTypeTag[type] : undefined) ??
          creativeTypeTag[CreativeType.PHOTO];

        return (
          <>
            <AdCreativesTilesTile
              key={ad.adId}
              ad={ad}
              tag={tag}
              thumbnail={thumbnail}
              onTileClick={onTileClick}
              onSaveButtonClick={onToggleSaveAd}
            />
            {showInfiniteScroll && <InfiniteScroll onInfiniteScroll={onFetchNext} />}
          </>
        );
      })}
      {loading &&
        Array.from({ length: LOADING_TILE_COUNT }).map((_, index) => (
          <CustomCard
            label="loading"
            key={`loading-tile-${ads.length + index}`}
            loading
            size="tile2"
            bodyContent="loading"
            headerProps={{ heroUrl: 'loading', tag: creativeTypeTag[CreativeType.PHOTO] }}
          />
        ))}
    </TilesContainer>
  );
};

export default AdCreativesTiles;
