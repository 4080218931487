import { CreativeAd } from 'types/components/creatives';
import { MyCreativeSortBy } from '../types';
import { useSearchParams } from 'react-router-dom';
import * as date from 'date-fns';

const MAX_AD_NAME_LENGTH = 30;

export const truncateText = (text: string) =>
  text.length > MAX_AD_NAME_LENGTH ? `${text.slice(0, MAX_AD_NAME_LENGTH)}...` : text;

export const sortAdsBySpend = (ads: CreativeAd[], sortBy: MyCreativeSortBy) => {
  if (sortBy === MyCreativeSortBy.HIGHESTSPEND) {
    const highestSpendSortedAds = [...ads].sort((ad1, ad2) => ad2.spend - ad1.spend);

    return highestSpendSortedAds;
  }
  const lowestSpendSortedAds = [...ads].sort((ad1, ad2) => ad1.spend - ad2.spend);

  return lowestSpendSortedAds;
};

export const getInitialDateRange = () => {
  const [searchParams] = useSearchParams();
  const endDate = date.endOfYesterday().toISOString();
  const startDate = date.sub(new Date(), { days: 7 }).toISOString();

  const startDateFromSearchParams = date.parseISO(searchParams?.get('startDate') || startDate);
  const endDateFromSearchParams = date.parseISO(searchParams?.get('endDate') || endDate);

  return {
    endDate: endDateFromSearchParams,
    startDate: startDateFromSearchParams,
  };
};

export const dollarDisplayValue = (value: number | string | undefined) => {
  if (value && Number.isNaN(+value)) return '--';

  if (value) return `$${(+value).toFixed(2)}`;

  return '--';
};

export const percentDisplayValue = (value: number | string | undefined) => {
  if (value && Number.isNaN(+value)) return '--';

  if (value) return `${(+value).toFixed(2)}%`;

  return '--';
};
