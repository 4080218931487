import React, { useEffect } from 'react';
import { debounce } from 'lodash';
import styled from '@emotion/styled';

const Scroller = styled.div`
  width: 10px !important;
  height: 10px !important;
  position: relative;
  bottom: -487px;
`;

type InfiniteScrollProps = {
  onInfiniteScroll?: () => void;
};

const debouncer = debounce((cb) => cb(), 1000);

const InfiniteScroll = (props: InfiniteScrollProps) => {
  const { onInfiniteScroll } = props;
  const ref = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          debouncer(() => onInfiniteScroll?.());
          observer.disconnect();
        }
      });
    });

    if (ref.current) observer.observe(ref.current);

    return () => {
      if (ref.current) observer?.unobserve(ref.current as Element);
    };
  }, []);

  return <Scroller ref={ref} />;
};

export default InfiniteScroll;
