import styled from '@emotion/styled';
import { Button, ShopifyWhite, Text } from '@innovationdepartment/proxima-ui';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  gap: 8px;
  align-self: stretch;
  background: linear-gradient(90deg, rgba(201, 250, 236, 0.9), rgba(203, 219, 254, 0.9));
  backdrop-filter: blur(5px);
  position: absolute;
  width: 100%;
  height: -webkit-fill-available;
  top: 69px;
  z-index: 4;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  width: 900px;
  text-align: center;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const ResizedButton = styled(Button)`
  padding: 12px;
`;

type CustomerAdsNoShopifyIntegrationProps = {
  onConnectShopifyAccountClicked: () => void;
};

const CustomerAdsNoShopifyIntegration = (props: CustomerAdsNoShopifyIntegrationProps) => {
  const { onConnectShopifyAccountClicked } = props;

  return (
    <Container>
      <ContentContainer>
        <InnerContainer>
          <Text variant="h2">Look beyond your category for inspiration!</Text>
          <Text variant="h6" color="neutral70" isTermina={false}>
            Explore the top ads from brands that your customers love!
          </Text>
        </InnerContainer>
        <InnerContainer>
          <ResizedButton
            onClick={onConnectShopifyAccountClicked}
            size="medium"
            startIcon={<ShopifyWhite />}
            label={<Text variant="body2Semibold">Connect Shopify</Text>}
          />
        </InnerContainer>
      </ContentContainer>
    </Container>
  );
};

export default CustomerAdsNoShopifyIntegration;
