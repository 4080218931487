import styled from '@emotion/styled';
import { ProximaLogoWithTextBlack, colors } from '@innovationdepartment/proxima-ui';

type SetupNavBarProps = {
  backgroundColor?: string;
};

type SetupLogoNavbarProps = SetupNavBarProps;

const SetupNavbar = styled.div<SetupNavBarProps>`
  padding: 48px 72px 0;
  align-self: stretch;
  text-align: center;
  & path {
    fill: ${colors.neutral100};
  }
  background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : 'inherit')};
`;

const SetupLogoNavbar = (props: SetupLogoNavbarProps) => (
  <SetupNavbar {...props}>
    <ProximaLogoWithTextBlack />
  </SetupNavbar>
);

export default SetupLogoNavbar;
