import styled from '@emotion/styled';
import { colors, Manufacturing, Text } from '@innovationdepartment/proxima-ui';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0;
  gap: 8px;
  align-self: stretch;
  position: absolute;
  width: 100%;
  background-color: ${colors.white};
  height: -webkit-fill-available;
  top: 130px;
  z-index: 4;
  overflow: hidden;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  text-align: center;
`;

const ImageContainer = styled.div``;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
`;

const CustomerAdsNoColal = () => (
  <Container>
    <ContentContainer>
      <ImageContainer>
        <Manufacturing />
      </ImageContainer>
      <InnerContainer>
        <Text variant="h2">We&apos;re analyzing your customers shopping trends!</Text>
        <Text variant="h6" color="neutral70" isTermina={false}>
          We&apos;ll let you know when your insights and inspiration are ready to explore!
        </Text>
      </InnerContainer>
    </ContentContainer>
  </Container>
);

export default CustomerAdsNoColal;
