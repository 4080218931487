import styled from '@emotion/styled';
import { CustomerAdsViewProps } from '../../types';
import { Button, colors, CustomCard, PrivacyOnSmall, Text } from '@innovationdepartment/proxima-ui';
import { CATEGORIES } from 'constants/categories';
import AdCreativeTileSaveButton from 'components/Insights/SharedLayout/AdCreativeTile/AdCreativeTile.SaveButton';
import { AdCreativesTilesProps } from 'components/Insights/SharedLayout/AdCreativesTiles/types';

const BodyContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  & .tile-button {
    align-self: flex-start;
  }
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const CustomButton = styled(Button)`
  max-width: 48px;
  margin-top: 24px;
  padding: 4px 12px;
  min-width: unset;
  border-color: ${colors.neutral10};
`;

type CustomerAdsTilesTileProps = {
  ad: CustomerAdsViewProps['ads'][number];
  thumbnail: string;
  tag: { label: string; icon: JSX.Element } | undefined;
  onTileClick: CustomerAdsViewProps['onTileClick'];
  onSaveButtonClick?: AdCreativesTilesProps['onToggleSaveAd'];
};

const CustomerAdsTilesTile = (props: CustomerAdsTilesTileProps) => {
  const { ad, thumbnail, tag, onTileClick, onSaveButtonClick } = props;

  /* TODO(Jenky): to add when the functionality is brought up from Product */
  const onButtonClick = () => {};

  return (
    <CustomCard
      size="tile2"
      onClick={() => onTileClick(ad)}
      bodyContent={
        <BodyContentWrapper>
          <TitleWrapper>
            <Text variant="tag1Semibold">{ad.brand?.name}</Text>
            <Text color="neutral70" variant="body2Medium">
              {ad?.category && CATEGORIES[ad?.category]}
            </Text>
          </TitleWrapper>
          <CustomButton
            label={undefined}
            onClick={onButtonClick}
            color="secondary"
            variant="outlined"
          >
            <PrivacyOnSmall />
          </CustomButton>
          {onSaveButtonClick && (
            <AdCreativeTileSaveButton
              saved={ad.isSaved ?? false}
              onClick={() => {
                onSaveButtonClick(ad.adId);
              }}
            />
          )}
        </BodyContentWrapper>
      }
      label={undefined}
      headerProps={{
        heroUrl: thumbnail,
        tag,
      }}
    />
  );
};

export default CustomerAdsTilesTile;
