import { Outlet } from 'react-router-dom';
import SetupLogoNavbar from 'ui/SetupLayout/SetupLogoNavbar';

const AuthView = () => (
  <>
    <SetupLogoNavbar />
    <Outlet />
  </>
);
export default AuthView;
