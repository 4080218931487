import styled from '@emotion/styled';
import { colors } from '@innovationdepartment/proxima-ui';
import { CustomerAdsFiltersProps } from '../../types';
import CustomerAdsFiltersDatePicker from './CustomerAdsFilters.DatePicker';
import CustomerAdsFiltersStaticCopy from './CustomerAdsFilters.StaticCopy';

const FiltersContainer = styled.div`
  padding: 12px 32px;
  background-color: ${colors.neutral0};
  width: calc(100% + 64px);
  margin: -32px 0 0 -32px;
  display: flex;
  border-bottom: 1px solid ${colors.neutral20};

  & > div {
    flex: 1;
    gap: 12px;
    display: flex;

    &:first-of-type {
      justify-content: flex-start;
    }

    &:last-of-type {
      justify-content: flex-end;
    }
  }
`;

const FiltersRight = styled.div``;

const FiltersLeft = styled.div``;

const CustomerAdsFiltersView = (props: CustomerAdsFiltersProps) => (
  <FiltersContainer>
    <FiltersRight>
      <CustomerAdsFiltersStaticCopy />
    </FiltersRight>
    <FiltersLeft>
      <CustomerAdsFiltersDatePicker {...props} />
    </FiltersLeft>
  </FiltersContainer>
);

export default CustomerAdsFiltersView;
