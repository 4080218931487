import styled from '@emotion/styled';
import { CustomCard, Text } from '@innovationdepartment/proxima-ui';
import { CreativeAd } from 'types/components/creatives';

const BodyContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

type AdCreativeTileProps = {
  ad: CreativeAd;
  cardTitle: string;
  thumbnail: string;
  onTileClick: (creativeAd: CreativeAd) => void;
  tag: { label: string; icon: JSX.Element } | undefined;
  titleElements?: React.ReactNode;
  bodyElements?: React.ReactNode;
};

const AdCreativeTile = (props: AdCreativeTileProps) => {
  const { ad, cardTitle, thumbnail, tag, titleElements, bodyElements, onTileClick } = props;

  return (
    <>
      <CustomCard
        size="tile2"
        onClick={() => onTileClick(ad)}
        bodyContent={
          <BodyContentWrapper>
            <TitleWrapper>
              <Text variant="body2Semibold">{cardTitle}</Text>
              {titleElements}
            </TitleWrapper>
            {bodyElements}
          </BodyContentWrapper>
        }
        label={undefined}
        headerProps={{
          heroUrl: thumbnail,
          tag,
        }}
      />
    </>
  );
};

export default AdCreativeTile;
