import { useEffect } from 'react';
import styled from '@emotion/styled';
import { ProximaTheme, colors, ToasterProvider } from '@innovationdepartment/proxima-ui';

import GlobalStyle from 'components/App/GlobalStyle';
import Router from 'Router';
import NavBar from 'components/NavBar';
import Loaders from 'components/Loaders';
import ErrorBoundaries from 'components/ErrorBoundaries';
import SpinnerWrapper from 'ui/SpinnerWrapper';
import { initDataDog } from 'lib/datadog';
import * as analytics from 'lib/analytics';
import Auth0UserAuth from 'components/Auth0UserAuth';
import FeatureFlagProvider from 'ui/FeatureFlagProvider';
import GaTag from './GaTag';

const AppWrapper = styled('div')`
  background-color: ${colors.bigBangBlack};
  width: 100vw;
  height: 100vh;
  display: flex;
`;

const App = () => {
  useEffect(() => {
    // initialize Segment.io
    analytics.initialize();
    initDataDog();
  }, []);

  return (
    <ToasterProvider>
      <ErrorBoundaries>
        <GaTag>
          <ProximaTheme>
            <SpinnerWrapper>
              <AppWrapper>
                <Auth0UserAuth>
                  <FeatureFlagProvider>
                    <Loaders>
                      <GlobalStyle />
                      <NavBar />
                      <Router />
                    </Loaders>
                  </FeatureFlagProvider>
                </Auth0UserAuth>
              </AppWrapper>
            </SpinnerWrapper>
          </ProximaTheme>
        </GaTag>
      </ErrorBoundaries>
    </ToasterProvider>
  );
};

export default App;
